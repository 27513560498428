<!-- Use preprocessors via the lang attribute! e.g. <template lang="pug"> -->
<template>
  <div class="w-full relative" ref="target">
    <template v-if="showPlaceholder">
      <label
        for="autocomplete"
        class=" block text-sm font-medium text-gray-700 dark:text-gray-400 text-left mb-1
        "
      >
        {{ placeholder }}
      </label>
    </template>

    <div
      class=" relative border overflow-hidden rounded-md shadow-sm bg-white dark:bg-gray-750
      "
      :class="
        errors
          ? 'border-red-500 text-red-500 placeholder-red-500'
          : 'border-gray-300 dark:border-gray-500'
      "
    >
      <template v-if="multiple && showBadges">
        <template v-for="(item, index) in inputValueArray" :key="index">
          <template v-if="item">
            <Badge
              :text="item[itemName]"
              class="bg-gray-400 text-gray-100 mr-2 mb-2 md:mb-0"
              :small="true"
            />
          </template>
        </template>
      </template>
      <template v-if="url">
        <div class="relative">
          <input
            ref="autocomplete"
            type="text"
            id="autocomplete"
            v-on:keydown.enter.prevent="input"
            @focus="showOptions = true"
            v-model="inputValue[itemName]"
            class="
              w-full
              pl-3
              py-2
              border-0
              autocomplete
              relative
              z-10
              bg-transparent
              focus:border-0 focus:ring-0
              dark:text-gray-200
              sm:text-sm
            "
            autocomplete="off"
            :placeholder="selectedArrayText ? selectedArrayText : placeholder"
            :class="[
              errors ? 'text-red-500 placeholder-red-500' : '',
              cleanable ? 'pr-[80px]' : 'pr-14',
              selectedArrayText
                ? 'placeholder-gray-900 dark:placeholder-gray-300'
                : '',
            ]"
          />
          <button class="absolute top-1.5 right-8 cursor-pointer z-10 dark:text-gray-300" @click.prevent="input">
            <SearchIcon class="w-6 h-6" />
          </button>
        </div>
      </template>
      <template v-else>
        <input
          ref="autocomplete"
          type="text"
          id="autocomplete"
          @input="input"
          @focus="showOptions = true"
          v-model="inputValue[itemName]"
          class="
            w-full
            pl-3
            py-2
            border-0
            autocomplete
            relative
            z-10
            bg-transparent
            focus:border-0 focus:ring-0
            dark:text-gray-200
            sm:text-sm
          "
          autocomplete="off"
          :placeholder="selectedArrayText ? selectedArrayText : placeholder"
          :class="[
            errors ? 'text-red-500 placeholder-red-500' : '',
            cleanable ? 'pr-14' : 'pr-7',
            selectedArrayText
              ? 'placeholder-gray-900 dark:placeholder-gray-300'
              : '',
          ]"
        />
      </template>

      <span class="absolute right-0 top-0 flex h-full items-center z-0">
        <template v-if="!isLoadingItem">
          <span class="block mr-1.5 text-gray-400">
            <ChevronDownIcon class="w-5 h-5" />
          </span>
        </template>
        <template v-else>
          <Loading />
        </template>
      </span>

      <template v-if="cleanable && inputValue.name">
        <div class="absolute right-8 top-0 flex h-full items-center z-10" :class="url ? 'right-[60px]' : ''">
          <button
            type="button"
            class="block text-gray-400 hover:text-gray-600"
            @click.prevent="clearAutocomplete"
          >
            <XIcon class="w-5 h-5" />
          </button>
        </div>
      </template>
    </div>
    <p
      v-if="errors"
      class="mt-2 text-sm text-red-500 absolute -bottom-5 left-0 pl-3"
      id="input2-error"
    >
      {{ errors[0] }}
    </p>
    <div
      v-show="autocompleteItems.length && showOptions"
      class=" absolute w-full z-50 bg-white dark:bg-gray-750 border border-gray-300 dark:border-gray-500 mt-1 max-height-48 overflow-hidden overflow-y-scroll rounded-md shadow-md
      "
      :class="top ? 'bottom-10' : ''"
    >
      <ul class="py-1 item-list">
        <template
          v-for="(value, index) in autocompleteItems"
          :key="value[itemId]"
        >
          <template v-if="multiple">
            <li
              v-if="value[itemId]"
              @click="setInputArrayValue"
              class=" px-3 py-2 cursor-pointer hover:bg-main4 hover:text-white relative group
              "
            >
              <Checkbox
                :text="value[itemName]"
                :value="value"
                v-model="inputValueArray[index]"
                labelClass="group-hover:text-white block"
                labelContainerClass="w-full"
              />
            </li>
          </template>

          <template v-else>
            <li
              v-if="value[itemId]"
              @click="
                () => {
                  setInput(value);
                  itemClick(value);
                }
              "
              class="
                px-3
                py-2
                cursor-pointer
                hover:bg-main4
                dark:text-gray-300
                hover:text-white
                relative
                group
              "
            >
              {{ value[itemName] }}

              <template v-if="additionalFieldsBrackets">
                <template
                  v-for="(text, index) in additionalFieldsBrackets"
                  :key="index"
                >
                  <span class="text-gray-400 text-sm group-hover:text-white"
                    >({{ value[text] }})</span
                  >
                </template>
              </template>

              <template v-if="additionalFields">
                <template
                  v-for="(text, index) in additionalFields"
                  :key="index"
                >
                  <span
                    class="block text-gray-400 text-sm group-hover:text-white"
                    >{{ value[text] }}</span
                  >
                </template>
              </template>
            </li>
          </template>
        </template>
      </ul>
    </div>
  </div>
</template>

<script>
import { debounce } from "vue-debounce";
import { ref } from "vue";
import { onClickOutside } from "@vueuse/core";
import axios from "axios";
import Loading from "@/components/Components/Loading";
import Checkbox from "@/components/Components/Checkbox";
import Badge from "@/components/Components/Badge";

import {
  ChevronDownIcon,
  XIcon,
  SearchIcon,
} from "@heroicons/vue/solid";

var baseUrl = process.env.NODE_ENV === 'production' ? process.env.VUE_APP_BASE_URL_PRODUCTION : process.env.VUE_APP_BASE_URL;

export default {
  name: "Autocomplete",
  setup(props, context) {
    const showOptions = ref(false);
    const target = ref(null);

    onClickOutside(target, (event) => {
      if (showOptions.value) {
        context.emit("autocompleteClose");
      }
      showOptions.value = false;
    });

    return { target, showOptions };
  },
  components: {
    Loading,
    Checkbox,
    Badge,
    ChevronDownIcon,
    XIcon,
    SearchIcon,
  },
  data() {
    return {
      inputValue: {
        default: true,
      },
      inputValueArray: [],
      show: false,
      selectedItem: {},
      searchRecord: null,
      autocompleteItems: [],
      isLoadingItem: false,
      selectedArrayText: "",
      queryParams: {},
    };
  },
  created() {
    this.queryParams = this.params ? this.params : {};
  },
  watch: {
    modelValue() {
      if (this.modelValue && !this.clearOnClick) {
        // this.inputValue = this.modelValue
        this.selectedItem = this.modelValue;
      } else {
        this.inputValue = { default: true };
        this.selectedItem = {};
      }

      if (!this.modelValue || this.modelValue.length < 1) {
        this.inputValueArray = this.modelValue;
      }
    },
    items() {
      this.autocompleteItems = this.items;
    },
    clearOnChange() {
      this.clearAutocomplete();
    },
  },
  props: {
    modelValue: {
      type: Object,
    },
    label: {
      type: String,
      default: null,
    },
    placeholder: {
      type: String,
      default: null,
    },
    items: {
      type: Array || Object,
      default: [],
    },
    itemName: {
      type: String,
      default: "name",
    },
    itemId: {
      type: String,
      default: "id",
    },
    top: {
      type: Boolean,
      default: false,
    },
    errors: {
      type: Array,
    },
    url: {
      type: String,
      default: null,
    },
    params: {},
    clearOnClick: {
      type: Boolean,
      default: false,
    },
    cleanable: {
      type: Boolean,
      default: true,
    },
    additionalFields: {
      type: Array,
      default: null,
    },
    additionalFieldsBrackets: {
      type: Array,
      default: null,
    },
    showItems: {
      type: Boolean,
      default: false,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    showPlaceholder: {
      type: Boolean,
      default: true,
    },
    showBadges: {
      type: Boolean,
      default: true,
    },
    clearOnChange: {
      default: false,
    },
    closeOnClick: {
      type: Boolean,
      default: true,
    },
  },
  emits: [
    "update:modelValue",
    "autocompleteItem",
    "change",
    "checkboxClick",
    "autocompleteClose",
    "itemClick",
    "clear",
  ],

  methods: {
    itemClick() {
      this.$emit("itemClick", this.modelValue);

      this.showOptions = !this.closeOnClick
    },
    setInput(value) {
      this.selectedItem = value;

      this.inputValue[this.itemName] = value[this.itemName];
      this.inputValue[this.itemId] = value[this.itemId];
      this.inputValue.default = false;

      this.$emit("update:modelValue", this.selectedItem);
      this.$emit("autocompleteItem", this.selectedItem);
      this.$emit("change", this.modelValue);

      if (this.clearOnClick) {
        this.inputValue = {
          default: true,
        };

        if (this.showItems) {
          this.autocompleteItems = this.items;
        } else {
          this.autocompleteItems = [];
        }
      }
    },
    setInputArrayValue: debounce(function () {
      if (!this.showBadges) {
        this.selectedArrayText = this.inputValueArray
          .filter(function (el) {
            return el;
          })
          .map((item) => item[this.itemName])
          .join(", ");
      }
      let inputValueArray = this.inputValueArray.filter((value) => value);

      this.$emit("update:modelValue", inputValueArray);
      this.$emit("autocompleteItem", inputValueArray);
      this.$emit("change", this.modelValue);
      this.$emit("checkboxClick");
    }, 100),
    resultQuery() {
      if (this.inputValue[this.itemName]) {
        let data = [];

        if (this.url) {
          if (this.inputValue[this.itemName].length > 2) {
            this.apiSearch();
          }
        } else {
          data = this.items.filter((item) => {
            const itemText = item[this.itemName].toLowerCase();
            if (
              typeof item == "object" &&
              itemText.includes(this.inputValue[this.itemName].toLowerCase())
            ) {
              return item;
            }
          });
        }

        this.autocompleteItems = data;
      }
    },
    apiSearch() {
      this.isLoadingItem = true;
      // let queryParams = this.queryParams ? this.queryParams : {}

      this.queryParams = this.params
        ? (this.queryParams = this.params)
        : this.queryParams;
      this.queryParams["q"] = this.inputValue[this.itemName];

      this.autocompleteItems = [];

      axios
        .get(baseUrl + this.url, {
          params: this.queryParams,
        })
        .then((response) => {
          this.autocompleteItems = response.data.data;
        })
        .catch((error) => {
          this.isLoadingItem = false;
        })
        .finally(() => {
          this.isLoadingItem = false;

          if (this.queryParams && this.queryParams.select_id) {
            const select = this.autocompleteItems.filter(
              (item) => item[this.itemId] == this.queryParams.select_id
            );
            this.setInput(select[0]);
            this.queryParams.select_id = null;
          }
        });
    },
    input() {
      this.resultQuery();
    },
    clearAutocomplete() {
      this.inputValue = {
        default: true,
      };
      this.inputValue[this.itemName] = null;
      this.selectedItem = null;
      this.inputValueArray = []
      this.$emit("update:modelValue", this.selectedItem);
      this.$emit("clear");

      if (this.url) {
        this.autocompleteItems = [];
      }
    },
  },
  mounted() {
    if (this.modelValue) {
      this.inputValue = this.modelValue;
      this.selectedItem = this.modelValue;
      if (this.multiple) {
        this.inputValue = this.modelValue;

        this.inputValueArray = this.items.map((item) =>
          this.selectedItem.includes(String(item.id)) ? item : undefined
        );

        if (!this.showBadges) {
          this.selectedArrayText = this.inputValueArray
            .filter(function (el) {
              return el;
            })
            .map((item) => item[this.itemName])
            .join(", ");
        }
      }
    }

    if (this.showItems) {
      this.autocompleteItems = this.items;
    }

    if (this.queryParams && this.queryParams.q) {
      this.inputValue[this.itemName] = this.queryParams.q;
      this.resultQuery();
    }
  },
};
</script>

<style lang="scss" scoped>
.max-height-48 {
  max-height: 200px;
}
</style>
